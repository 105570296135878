import orthoticsIcon from '../../assets/home/custom-orthotics-master-icon.png';
import kintecAnywhereIcon from '../../assets/home/anywhere-master-icon.png';
import footwearIcon from '../../assets/home/footwear-master-icon.png';
import bracingIcon from '../../assets/home/bracing-master-icon.png';
import compressionIcon from '../../assets/home/compression-master-icon.png';

import mobileOrthoticsIcon from '../../assets/home/mobile/icon-custom-orthotics.png';
import mobileKintecAnywhereIcon from '../../assets/home/mobile/icon-kintec-anywhere.png';
import mobileFootwearIcon from '../../assets/home/mobile/icon-footwear.png';
import mobileBracingIcon from '../../assets/home/mobile/icon-bracing.png';
import mobileCompressionIcon from '../../assets/home/mobile/icon-compression.png';

import navLeftArrow from '../../assets/home/nav-left-master.png';
import navRightArrow from '../../assets/home/nav-right-master.png';

import mobileLeftArrow from '../../assets/home/mobile/arrow-left.png';
import mobileRightArrow from '../../assets/home/mobile/arrow-right.png';

import './SlideShow.scss';
import Carousel from 'react-bootstrap/Carousel';

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const SlideShow = (props: any) => {
    const selectedIndex = props.selectedIndex;
    const [index, setIndex] = useState(0);

    let [prevIcon, setPrevIcon] = useState(<span><img src={navLeftArrow} /></span>);
    let [nextIcon, setNextIcon] = useState(<span><img src={navRightArrow} /></span>);

    const handleSelect = (selectedIndex: number, event: any) => {
        setIndex(selectedIndex);
        props.arrowClick(selectedIndex);
    };

    const salmonArmLink = '<p>For Salmon Arm - click <a href="https://www.kintec.net/salmon-arm" target="_blank">here</a> </p>';

    useEffect(() => {
        setIndex(selectedIndex);

        if(window.innerWidth < 768){
            setPrevIcon(<span><img src={mobileLeftArrow} alt="Left navigation arrow"/></span>);
            setNextIcon(<span><img src={mobileRightArrow} alt="Right navigation arrow"/></span>);
        }
    }, [selectedIndex])

    return (

        <Carousel slide={false} indicators={false} interval={15000} prevIcon={prevIcon} nextIcon={nextIcon} activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item className='custom-orthotics'>
                <img src={mobileOrthoticsIcon} className="mobile-icon"/>
                <Carousel.Caption>
                    <img src={orthoticsIcon} />
                    <h3>Custom Orthotics</h3>
                    <p>Get relief from foot and lower body pain.</p>
                    <p>
                    Nobody does it better. Since 1991 we have been designing and engineering custom orthotics to
                    help people stay active and on their feet. Backed by our comfort guarantee the Pedorthists at
                    Kintec do more than just look at your feet. We look at your life...and tailor a treatment plan
                    that will have you pain free and moving as quickly as possible.
                    </p>
                    <Link to="/booking/orthotic-assessment" className="btn btn-primary">BOOK AN APPOINTMENT</Link>
                    <br />
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: salmonArmLink }} />
                    <div className="dots">
                        <div className="dot active"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            {/* <Carousel.Item className='anywhere'>
                <img src={mobileKintecAnywhereIcon} className="mobile-icon"/>
                <Carousel.Caption>
                    <img src={kintecAnywhereIcon} />
                    <h3>Kintec Anywhere</h3>
                    <p>Kintec continues to help Canadians to be active, on their feet, for life. With just
                    three easy steps, you can get your custom orthotics anywhere in Canada delivered to your
                    doorstep. Simply book an appointment to start your foot health journey.
                    </p>
                    <Link to="/booking/kintec-anywhere" className="btn btn-primary">BOOK AN APPOINTMENT</Link>

                    <div className="dots">
                        <div className="dot"></div>
                        <div className="dot active"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item> */}

            <Carousel.Item className='footwear'>
                <img src={mobileFootwearIcon} className="mobile-icon"/>
                <Carousel.Caption>
                        <img src={footwearIcon} />
                        <h3>Footwear Solutions</h3>
                        <p>
                        Let's face it, this is where the foot hits the road, trail or office. Get the most out of today's 
                        footwear with our fitting appointment. You'll never look at buying shoes the same
                        way again. Did we mention free?! We don't charge extra for this service and we'll price match
                        any competitors.
                        </p>
                        <Link to="/booking/footwear" className="btn btn-primary">BOOK AN APPOINTMENT</Link>
                        <br />
                        <br />
                        <div dangerouslySetInnerHTML={{ __html: salmonArmLink }} />
                        <div className="dots">
                            <div className="dot"></div>
                            <div className="dot active"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item className='bracing'>
                <img src={mobileBracingIcon} className="mobile-icon"/>
                <Carousel.Caption>
                    <img src={bracingIcon} />
                    <h3>Bracing</h3>
                    <p>Start your recovery today.</p>
                    <p>Looking to recover as soon as possible or prevent an injury? Our Human Kinetic Specialists are
                        here to help. From Toes to Nose we source the very best and we’ll fit you with the brace that’s
                        right for you.
                    </p>
                    <Link to="/booking/bracing" className="btn btn-primary">BOOK AN APPOINTMENT</Link>
                    <br />
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: salmonArmLink }} />
                    <div className="dots">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot active"></div>
                        <div className="dot"></div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item className='compression'>
                <img src={mobileCompressionIcon} className="mobile-icon"/>
                <Carousel.Caption>
                    <img src={compressionIcon} />
                    <h3>Compression</h3>
                    <p>Stay comfortable and supported.</p>
                    <p>
                    Standing and sitting for long periods is hard on the body and can lead to painful varicose veins.
                    Let’s not even talk about long distance air travel and those swollen feet and ankles!
                    That’s where Compression Therapy comes in. Meet with a Fit Expert to explore what is best for
                    you!
                    </p>
                    <Link to="/booking/compression" className="btn btn-primary">BOOK AN APPOINTMENT</Link>
                    <br />
                    <br />
                    <div dangerouslySetInnerHTML={{ __html: salmonArmLink }} />
                    <div className="dots">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot active"></div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
  );
};

export default SlideShow;